<template>
  <div class="pt-page company-profile">
    <!-- 佛塔背景图 -->
    <!-- <img
      class="pt-page__bg"
      src="../../assets/佛塔.png"
    /> -->
    <div class="pt-page__content pt-page__bg">
      <PtHeader :active="3"></PtHeader>
      <!-- body -->
      <div class="pt-page__wrapper">
        <!-- 标语入口 -->
        <pt-slogan></pt-slogan>
        <!-- 公司介绍 -->
        <div class="pt-section__wrapper">
          <div class="pt-paragraph" style="width: 700px">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/公司介绍.png"
              />
            </div>
            <div class="pt-paragraph__body flex-box">
              <div>
                <p>
                  <span>公司于</span>
                  <span class="text--primary">2017年6月</span>
                  <span>正式成立</span>
                </p>
                <p>前身是重庆诚与善电子商务有限公司。</p>
                <p>
                  <span>现主营</span>
                  <span class="text--primary">互联网信息服务</span>
                  <span>、</span>
                  <span class="text--primary">互联网游戏</span>
                </p>
              </div>
              <div>
                <!-- 公司介绍图片入口 -->
                <img
                  src="../../assets/公司介绍2.png"
                  style="width: 298px; height: 88px"
                />
              </div>
            </div>
          </div>
          <!-- 公司组织架构图入口 -->
          <div class="flex-box" style="justify-content: center">
            <img class="pt-org-structure" src="../../assets/组织架构4x.png" />
          </div>
          <!-- 团队风采 -->
          <div class="pt-paragraph" style="width: 100%">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/团队风采.png"
              />
            </div>
            <div
              class="pt-paragraph__body flex-box"
              style="justify-content: space-evenly"
            >
              <div>
                <img
                  style="width: 88px; height: 298px"
                  src="../../assets/团队风采2.png"
                />
              </div>
              <div>
                <p>因为缘分，让我们相聚在一起，公司让我们更像一家人。</p>
                <p>我们在这里收获事业、收获友情、互相成就。</p>
                <img
                  src="../../assets/团队合照.png"
                  style="width: 451px; height: 195px"
                />
              </div>
              <div>
                <img
                  src="../../assets/团队文化照.png"
                  style="width: 273px; height: 205px"
                />
              </div>
            </div>
          </div>
          <!-- 慈善捐款 -->
          <div class="pt-paragraph" style="width: 100%">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/慈善捐款.png"
              />
            </div>
            <div
              class="pt-paragraph__body flex-box"
              style="justify-content: center"
            >
              <div>
                <img src="../../assets/外出慰问活动.png" />
              </div>
              <div style="padding-left: 24px">
                <img
                  src="../../assets/慈善捐款记录.png"
                  style="width: 380px; height: 89px"
                />
                <div class="pt-donation">
                  <p>
                    <a
                      class="pt-link"
                      href="https://docs.qq.com/sheet/DQmVmVm1KY0VLdXVR?tab=BB08J2"
                      target="_blank"
                      >2023年02月12日 供养贵州南山寺财神殿 2万元 捐款记录</a
                    >
                  </p>
                  <p>
                    <a
                      class="pt-link"
                      href="https://docs.qq.com/sheet/DQmVmVm1KY0VLdXVR?tab=BB08J2"
                      target="_blank"
                      >2022年10月01日 捐赠：大法会 1.8万元 捐款记录</a
                    >
                  </p>
                  <p>
                    <a
                      class="pt-link"
                      href="https://docs.qq.com/sheet/DQmVmVm1KY0VLdXVR?tab=BB08J2"
                      target="_blank"
                      >2022年08月02日 捐赠：大法会 1.8万元 捐款记录</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- 经文 -->
          <pt-scripture></pt-scripture>
        </div>
      </div>
    </div>
    <!-- 页面底部信息 -->
    <pt-footer></pt-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$router.push({ name: "HomePage" });
    },
    onClick_1() {
      this.$router.push({ name: "CompanyProfile" });
    },
    onClick_2() {
      this.$router.push({ name: "ContactUs" });
    },
    onClick_3() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
    onClick_4() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
  },
};
</script>

<style lang="less">
.company-profile {
  .pt-paragraph__body {
    p {
      margin: 16px 0;
    }
  }
  .pt-link {
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
  .pt-donation {
    .pt-link {
      &::after {
        content: ">>";
        font-size: 16px;
      }
    }
  }
  .pt-org-structure {
    width: 921px;
    height: 357px;
    image-rendering: -moz-crisp-edges;
    image-rendering: -o-crisp-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: crisp-edges;
    -ms-interpolation-mode: nearest-neighbor;
  }
}
</style>
