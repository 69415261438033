<template>
  <div class="pt-footer pt-copyright">
    <div class="pt-copyright__wrapper">
      <div>Copyright © 2008-2024 重庆菩提心科技有限公司 版权所有</div>
      <div>
        <a href="http://www.bijiaqi.com/images/icp.jpeg" target="_blank"
          >渝B2-20180050</a
        >&emsp;
        <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
          >渝ICP备11007723号-8</a
        >&emsp;
        <a href="http://www.bijiaqi.com/images/zhizhao.jpg" target="_blank">
          执照：91500112MA5UM4FU5E
        </a>
        &emsp;
        <a
          href="https://map.baidu.com/search/%E9%87%8D%E5%BA%86%E5%B8%82%E6%B8%9D%E5%8C%97%E5%8C%BA%E7%BA%BF%E5%A4%96%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD5%E6%A0%8B/@11864379.092364246,3437260.935,19z?querytype=s&da_src=shareurl&wd=%E9%87%8D%E5%BA%86%E5%B8%82%E6%B8%9D%E5%8C%97%E5%8C%BA%E7%BA%BF%E5%A4%96%E5%9F%8E%E5%B8%82%E8%8A%B1%E5%9B%AD5%E6%A0%8B&c=132&src=0&pn=0&sug=0&l=13&b=(11828411,3406296;11910331,3447800)&from=webmap&biz_forward=%7B%22scaler%22:2,%22styles%22:%22pl%22%7D&device_ratio=2"
          target="_blank"
          rel="noopener noreferrer"
          >地址：重庆市渝北区线外城市花园5栋</a
        >&emsp;咨询电话：023—67038908
      </div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PtFooter",
};
</script>

<style scoped lang="less">
.pt-copyright {
  display: flex;
  align-items: center;
  justify-content: center;
  .pt-copyright__wrapper {
    width: 100%;
    min-height: 220px;
  }
  div {
    text-align: center;
    margin-top: 24px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    color: #ffffff;
    &:hover {
      text-decoration: underline;
    }
  }
}
.pt-footer {
  color: #ffffff;
  background-color: #3b3b3b;
  font-family: SimSun;
  font-size: 16px;
}
</style>
