import Vue from 'vue';
import App from './app.vue';
import router from './router';

Vue.config.productionTip = false;

import PtHeader from './components/PtHeader.vue'
import PtSlogan from './components/PtSlogan.vue'
import PtFooter from './components/PtFooter.vue'
import PtScripture from './components/PtScripture.vue'
// 全局注册组件
Vue.component('PtHeader',PtHeader)
Vue.component('PtSlogan',PtSlogan)
Vue.component('PtFooter',PtFooter)
Vue.component('PtScripture',PtScripture)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app');
