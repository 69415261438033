<template>
  <div class="pt-page homepage">
    <!-- 佛塔背景图 -->
    <!-- <img
      class="pt-page__bg"
      src="../../assets/佛塔.png"
    /> -->
    <div class="pt-page__content pt-page__bg">
      <PtHeader></PtHeader>
      <!-- body -->
      <div class="pt-page__wrapper">
        <!-- 标语入口 -->
        <pt-slogan></pt-slogan>
        <div class="pt-section__wrapper">
          <!-- 财富自由源于布施 -->
          <div class="pt-paragraph" style="width: 628px">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                style="width: 188px"
                src="../../assets/财富自由源于布施.png"
              />
            </div>
            <div class="pt-paragraph__body">
              <div class="flex-box">
                <div>
                  <div>
                    <img
                      src="../../assets/财富自由.png"
                      style="width: 288px; height: 88px"
                    />
                  </div>
                  <div class="flex-box">
                    <p
                      style="
                        width: 288px;
                        padding-right: 70px;
                        margin-bottom: 0;
                      "
                    >
                      我们希望与菩提心科技公司有缘的所有员工股东用户及其家人都能获得
                      <span class="pt-small-slogan">财富自由</span>
                    </p>
                    <div>
                      <img
                        src="../../assets/使命.png"
                        style="width: 54px; height: 97px"
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <img
                    src="../../assets/劳作.png"
                    style="opacity: 0.46; width: 420px"
                  />
                </div>
              </div>
              <div class="flex-box" style="justify-content: space-around">
                <p class="pt-small-slogan" style="width: 288px; margin-top: 0"></p>
                <p style="flex: 1; margin-top: 0">
                  我们坚持以商场为道场，弘扬菩提心的善知识与生命的觉悟
                </p>
              </div>
            </div>
          </div>
          <!-- 身心自由源于戒定慧 -->
          <div class="pt-paragraph" style="width: 100%; max-width: 1200px">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                style="width: 203px"
                src="../../assets/身心自由源于戒定慧.png"
              />
            </div>
            <div class="pt-paragraph__body flex-box flex-x-center">
              <div class="pt-monk-wrapper">
                <img src="../../assets/和尚.png" class="pt-monk-img" />
                <img src="../../assets/和尚-云.png" class="pt-monk-cloud" />
                <div class="pt-monk__text">
                  <div style="text-align: right; padding-right: 32px">
                    <img
                      src="../../assets/价值观.png"
                      style="width: 144px; height: 57px; display: inline-block"
                    />
                  </div>
                  <div style="margin-right: 32px">
                    <p>精进、禅定、智慧</p>
                    <p>布施、持戒、忍辱</p>
                  </div>
                </div>
              </div>
              <div style="">
                <img
                  src="../../assets/身心自由.png"
                  style="width: 83px; height: 298px; margin-right: 32px"
                />
              </div>
              <div style="padding: 32px 0">
                <div class="pt-mentalfree__border"></div>
                <div style="padding: 16px">
                  <p style="width: 100%; max-width: 263px; font-size: 26px">
                    我们希望与菩提心科技公司有缘的所有员工股东用户及其家人都能获得&nbsp;
                    <span class="pt-small-slogan">身心自由</span>
                  </p>
                </div>
                <div class="pt-mentalfree__border"></div>
              </div>
            </div>
          </div>
          <!-- 生死自由源于净土 -->
          <div class="pt-paragraph">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                style="width: 203px"
                src="../../assets/生死自由源于净土.png"
              />
            </div>
            <div class="pt-paragraph__body flex-box flex-x-center">
              <div>
                <img
                  src="../../assets/生死自由配图.png"
                  style="width: 298px; height: 88px"
                />
                <p style="max-width: 315px; margin-top: 68px">
                  我们希望与菩提心科技公司有缘的所有员工股东用户及其家人都能获得
                  <span class="pt-small-slogan"> 生死自由 </span>
                </p>
              </div>
              <div class="pt-y-divider"></div>
              <div>
                <img
                  src="../../assets/愿景.png"
                  style="width: 91px; height: 57px"
                />
                <p style="max-width: 413px">
                  致力于吸纳天下有缘志士，以财力、资源共育，坚持以“菩提心”渡友，成就星火燎原，广利社会。
                </p>
                <img
                  src="../../assets/佛像群.png"
                  style="filter: blur(2.5px)"
                />
              </div>
            </div>
          </div>
          <!-- 经文 -->
          <pt-scripture></pt-scripture>
        </div>
      </div>
    </div>
    <!-- 页面底部信息 -->
    <pt-footer></pt-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$router.push({ name: "HomePage" });
    },
    onClick_1() {
      this.$router.push({ name: "CompanyProfile" });
    },
    onClick_2() {
      this.$router.push({ name: "ContactUs" });
    },
    onClick_3() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
    onClick_4() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
  },
};
</script>

<style lang="less">
@import url(../../style/variable.less);
.pt-small-slogan {
  font-size: 32px !important;
  color: @primary-color;
}
.pt-y-divider {
  height: 300px;
  width: 2px;
  background-color: #979797;
  margin: 0 32px;
}
.homepage {
  img {
    display: block;
  }
  .pt-monk-wrapper {
    position: relative;
    .pt-monk-img {
      width: 500px;
      margin-left: -200px;
      opacity: 0.46;
    }
    .pt-monk__text {
      position: absolute;
      top: 0;
      right: 16px;
    }
    .pt-monk-cloud {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }
  .pt-mentalfree-bg {
    background: url(../../assets/和尚.png) no-repeat;
  }
  .pt-mentalfree__border {
    width: 318px;
    height: 11px;
    border: 2px solid #777f8b;
  }
  .pt-paragraph {
    padding-left: 0;
  }
  .pt-paragraph__body {
    p {
      margin: 16px 0;
    }
  }
  .pt-link {
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
  .pt-donation {
    .pt-link {
      &::after {
        content: ">>";
        font-size: 16px;
      }
    }
  }
}
</style>
