<template>
  <div class="pt-page">
    <!-- 佛塔背景图 -->
    <!-- <img
      class="pt-page__bg"
      src="../../assets/佛塔.png"
    /> -->
    <div class="pt-page__content pt-page__bg">
      <PtHeader :active="2"></PtHeader>
      <!-- body -->
      <div class="pt-page__wrapper">
        <!-- 标语入口 -->
        <pt-slogan></pt-slogan>
        <!-- 产品介绍 -->
        <div class="pt-section__wrapper">
          <div class="pt-paragraph">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/产品介绍.png"
              />
            </div>
            <div class="pt-paragraph__body flex-box">
              <img
                style="width: 88px; height: 298px"
                src="../../assets/产品介绍-side.png"
              />
              <div style="flex: 1; padding-left: 64px">
                <p class="pt-paragraph__head">比价器</p>
                <p>
                  游戏币比价器,又称“海外游戏币批发市场”，是一个欧美PC端游戏的中国职业玩家们（即游戏币生产者）向欧美零售商出售游戏币的B2B交易平台，也是目前海外游戏币最活跃的收出货平台
                </p>
              </div>
            </div>
            <div class="pt-paragraph__footer" style="padding: 0">
              <a class="pt-paragraph__link" href="https://www.bijiaqi.com"
                >www.bijiaqi.com</a
              >
            </div>
          </div>
          <div class="pt-paragraph">
            <div class="pt-paragraph__head">GAMEUSD</div>
            <div class="pt-paragraph__body">
              <p>
                GAMEUSD
                是我司自主开发的游戏币交易平台，主要提供零售商和海外玩家交易信息和担保的主要销售市场上爆款游戏币，如80级魔兽、失落方舟、新世界、流放之路等。
              </p>
            </div>
            <div class="pt-paragraph__footer">
              <a class="pt-paragraph__link" href="https://www.gameusd.com"
                >www.gameusd.com</a
              >
            </div>
          </div>
          <div class="pt-paragraph">
            <div class="pt-paragraph__head">爸爸的时光</div>
            <div class="pt-paragraph__body flex-box">
              <div class="media-text-wrapper">
                <p>
                  以新媒体为载体孵化的新零售电商，主打亲子互动课堂、玩具、真人经验分享等。
                </p>
                <p>
                  旨在促进父亲们认识到亲子互动的重要性，同时运用正确的方式相处，并借助爸爸的时光品牌下的产品或方法达到高质量陪伴。
                </p>
              </div>
              <img class="media__img" src="../../assets/baba-qrcode.png" />
            </div>
          </div>
        </div>
        <!-- 死亡学研究及临终助念慈善基金 -->
        <div class="pt-section__wrapper">
          <div class="pt-section" style="width: 296px; margin: 0 auto">
            <img
              class="pt-section__subtitle"
              style="width: 100%"
              src="../../assets/死亡学研究及临终助念慈善基金.png"
            />
          </div>
          <div class="pt-paragraph">
            <div class="pt-paragraph__body flex-box">
              <div class="media-text-wrapper">
                <p>
                  死亡学研究及临终助念慈善基金。该基金将与大学、医院等科研机构合作开展死亡学的科学研究，以佛经与科研结论为依据，开展或赞助临终关怀事业。主要目标是尽量的帮助更多的有缘人往生净土，解决死亡问题。
                </p>
              </div>
              <img
                style="width: 88px; height: 298px"
                src="../../assets/公益产品.png"
              />
            </div>
          </div>
        </div>
        <!-- 一切如来心秘密全身舍利宝箧印陀罗塔 -->
        <pt-scripture></pt-scripture>
      </div>
    </div>
    <!-- 页面底部信息 -->
    <pt-footer></pt-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$router.push({ name: "HomePage" });
    },
    onClick_1() {
      this.$router.push({ name: "CompanyProfile" });
    },
    onClick_2() {
      this.$router.push({ name: "ContactUs" });
    },
    onClick_3() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
    onClick_4() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
  },
};
</script>

<style lang="less">
</style>
