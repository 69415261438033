<template>
  <!-- 标语 -->
  <div class="pt-slogan">
    <img class="pt-slogan__item" src="../assets/敦伦尽分.png" />
    <img class="pt-slogan__item" src="../assets/闲邪存诚.png" />
    <img class="pt-slogan__item" src="../assets/诸恶莫作.png" />
    <img class="pt-slogan__item" src="../assets/众善奉行.png" />
  </div>
</template>

<script>
export default {
  name: "PtSlogan",
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.pt-slogan {
  display: flex;
  justify-content: center;
  padding-top: 94px;
}
.pt-slogan__item {
  width: 88px;
  height: 298px;
  display: block;
  margin-right: 64px;
  &:last-child {
    margin-right: 0;
  }
}
</style>
