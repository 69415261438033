<template>
  <div class="pt-page contact-us">
    <!-- 佛塔背景图 -->
    <!-- <img
      class="pt-page__bg"
      src="../../assets/佛塔.png"
    /> -->
    <div class="pt-page__content pt-page__bg">
      <PtHeader :active="4"></PtHeader>
      <!-- body -->
      <div class="pt-page__wrapper">
        <!-- 标语入口 -->
        <pt-slogan></pt-slogan>
        <!-- 招聘介绍 -->
        <div class="pt-section__wrapper">
          <div class="pt-paragraph">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/招聘介绍.png"
              />
            </div>
            <div class="pt-paragraph__body">
              <div class="flex-box flex-x-center">
                <div style="margin-right: 48px">
                  <!-- 招聘介绍图片入口 -->
                  <img
                    src="../../assets/招聘介绍2.png"
                    style="width: 88px; height: 298px"
                  />
                </div>
                <div>
                  <!-- 招聘介绍文案入口 -->
                  <p>以商场为道场，广纳有缘志士，期待热情有干劲的你加入，</p>
                  <p>让你的梦想一触即发。我们诚聘以下人员：</p>
                  <p>
                    1、外贸专员：主要负责运用多样化方式开展海外市场调研及推广
                  </p>
                  <p>2、跨境电商运营专员：负责公司电商平台咨询等</p>
                  <p>3、网站运营:负责制定网站运营方案，提升网站搜索排名等。</p>
                  <p style="width: 650px">
                    职位福利：定期团建、下午茶、年度旅游、年底双薪、节假日慰问、生日福利等
                  </p>
                  <div class="flex-box">
                    <div>
                      <p>联系人: 刘女士</p>
                      <p>简历投递邮箱: Ld@putixin.cn</p>
                    </div>
                    <div style="padding-left: 32px">
                      <p>联系电话: 023-67038908</p>
                      <p>
                        <a
                          href="https://www.zhipin.com/gongsir/2f3212618c1b8d810HVy3t69.html"
                          target="_blank"
                          class="pt-link"
                          >查看招聘详情</a
                        >
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-box">
                <!-- 图片画廊入口 -->
                <div><img src="../../assets/会议1.png" /></div>
                <div style="padding-top: 80px; margin-left: -60px">
                  <img src="../../assets/会议2.png" />
                </div>
              </div>
            </div>
          </div>
          <!-- 业务合作 -->
          <div class="pt-paragraph" style="width: 100%">
            <div class="pt-section pt-center">
              <img
                class="pt-section__subtitle"
                src="../../assets/业务合作.png"
              />
            </div>
            <div
              class="pt-paragraph__body flex-box"
              style="width: 640px; margin: 0 auto"
            >
              <div style="flex: 1">
                <p style="width: 457px">
                  我们正在积极的开展业务合作如果您了解我们之后，希望与我们合作，我们将不胜荣幸。联系电话：023-67038908
                </p>
                <img src="../../assets/会议3.png" />
              </div>
              <div>
                <img
                  style="height: 298px; width: 88px"
                  src="../../assets/商务合作.png"
                />
              </div>
            </div>
          </div>
          <!-- 经文 -->
          <pt-scripture></pt-scripture>
        </div>
      </div>
    </div>
    <!-- 页面底部信息 -->
    <pt-footer></pt-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$router.push({ name: "HomePage" });
    },
    onClick_1() {
      this.$router.push({ name: "CompanyProfile" });
    },
    onClick_2() {
      this.$router.push({ name: "ContactUs" });
    },
    onClick_3() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
    onClick_4() {
      window.open("https://docs.qq.com/doc/DQmhWYURLTU15d0pt", "_blank");
    },
  },
};
</script>

<style lang="less">
.contact-us {
  .pt-paragraph {
    width: 100%;
    max-width: 1200px;
  }
  .pt-paragraph__body {
    p {
      margin: 16px 0;
    }
  }
}
</style>
