<template>
  <!-- PtHeader -->
  <div class="pt-header">
    <!-- logo+公司名称 -->
    <div class="pt-logo-wrapper" @click="goHome">
      <img class="pt-logo" src="../assets/logo.png" />
      <span class="pt-logo-text">重庆菩提心科技有限公司</span>
    </div>
    <!-- 导航条按钮 -->
    <div class="pt-menu-wrapper">
      <div
        class="pt-menu-item"
        :class="{ 'pt-menu-item--active': active === 1 }"
        @click="QuShouye"
      >
        <span class="pt-menu-item__text">首页</span>
      </div>
      <div
        class="pt-menu-item"
        :class="{ 'pt-menu-item--active': active === 2 }"
        @click="QuChanping"
      >
        <span class="pt-menu-item__text">产品</span>
      </div>
      <div
        class="pt-menu-item"
        :class="{ 'pt-menu-item--active': active === 3 }"
        @click="QuJieshao"
      >
        <span class="pt-menu-item__text">介绍</span>
      </div>
      <div
        class="pt-menu-item"
        :class="{ 'pt-menu-item--active': active === 4 }"
        @click="QuLianxi"
      >
        <span class="pt-menu-item__text">联系</span>
      </div>
    </div>
  </div>
</template>

  <script>
export default {
  props: {
    active: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {};
  },
  methods: {
    goHome() {
      if (this.$route.name === "HomePage") {
        window.location.reload();
      } else {
        this.$router.push({ name: "HomePage" });
      }
    },
    QuShouye() {
      this.$router.push({ name: "HomePage" });
    },
    QuChanping() {
      this.$router.push({ name: "ProductIntroduction" });
    },
    QuJieshao() {
      this.$router.push({ name: "CompanyProfile" });
    },
    QuLianxi() {
      this.$router.push({ name: "ContactUs" });
    },
  },
};
</script>

<style scoped lang="less">
@fontSize: 26px;
.pt-header {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 16px 0 48px 0;

  .pt-logo-wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    cursor: pointer;
    .pt-logo {
      width: 48px;
      height: 64px;
      display: inline-block;
      vertical-align: middle;
    }
    .pt-logo-text {
      // font-family: "SimSun";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: @fontSize;
      color: #000000;
    }
  }
  .pt-menu-wrapper {
    display: flex;

    .pt-menu-item {
      margin-right: 16px;
      width: 86px;
      height: 94px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    .pt-menu-item__text {
      color: #000000;
      font-family: "slideyouran";
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: @fontSize;
    }
    .pt-menu-item--active {
      background: url("../assets/menu-active.png") no-repeat center;
      background-size: cover;
    }
  }
}
</style>
