<template>
  <!-- 一切如来心秘密全身舍利宝箧印陀罗塔 -->
  <div class="pt-section__wrapper pt-scripture">
    <a href="https://docs.qq.com/doc/DQmhWYURLTU15d0pt" target="_blank">
      <div class="pt-section">
        <img
          class="pt-section__subtitle"
          style="width: 100%"
          src="../assets/一切如来心秘密全身舍利宝箧印陀罗塔.png"
        />
      </div>
      <!-- 经文内容 -->
      <div class="pt-paragraph">
        <div class="pt-paragraph__body">
          <img class="pt-scripture-img" src="../assets/经文内容.png" />
        </div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "PtScripture",
};
</script>
<style lang="less">
.pt-scripture {
  padding: 0 48px;
  .pt-section {
    width: 360px;
    padding: 0;
    margin: 0 auto;
    background-size: 360px 112px;
  }
  .pt-paragraph {
    width: 100%;
    max-width: 1100px;
    padding: 0;
    padding-bottom: 16px;
    .pt-scripture-img {
      width: 100%;
      display: block;
      margin: 0 auto;
      height: 140px;
    }
  }
}
</style>
